(function (angular, _) {
    var MyHippoProducerServices = angular.module('MyHippoProducer.Services', ['ngMaterial']);
    MyHippoProducerServices.factory('APIService', function ($log,toaster, UserService, $q, $http, $cookies) {
        const {API_HOST: apiHost} = window.appConfig;
        // Set global header to include bearer token
        // Needed for extra layer of security
        // Since we set the cookie to None for FirstConnect iframe - HIP41302
        const token = $cookies.get('hippo-agent-jwt');
        $http.defaults.headers.common.Authorization = `Bearer ${token}`;
        var APIService = {
            login: function (token) {
                return apiRequest($http.post(`${apiHost}/v1/producer/user/login`, {token}));
            },
            logout: function () {
                return apiRequest($http.post(`${apiHost}/v1/producer/user/logout`));
            },
            findCurrentUser: function (params) {
                const request = $http({
                    url: `${apiHost}/v1/producer/user`,
                    method: 'GET',
                    params: params
                });
                return apiRequest(request).then(userData => {
                    UserService.init(userData);
                    return userData;
                });
            },
            getRCEDocument: function (policyId) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policy/${policyId}/rce_document`,
                    method: 'GET'
                });
                return apiRequest(request);
            },
            getProducerQuoteId: function (policyId) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policy/${policyId}/producer_quote_id`,
                    method: 'GET'
                });
                return apiRequest(request);
            },
            loginWithAccessToken: function (accessToken, producerId) {
                const request = $http({
                    url: `${apiHost}/v1/producer/user/loginWithAccessToken`,
                    method: 'POST',
                    data: {accessToken, producerId}
                });
                return apiRequest(request);
            },
            findPolicies: function (params) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policy`,
                    method: 'GET',
                    params: params
                });
                return apiRequest(request);
            },
            findPendingPolicies: function (params) {
                const request = $http({
                    url: `${apiHost}/v1/producer/pendingPolicy`,
                    method: 'GET',
                    params: params
                });
                return apiRequest(request);
            },
            findPolicyById: function (policyId) {
                return apiRequest($http.get(`${apiHost}/v1/producer/policy/${policyId}`));
            },
            createLead: function (data) {
                if (UserService.isPolicyCreationDisabled()) {
                    const errorMessage = 'Not allowed to create policies';
                    $log.error(errorMessage);
                    return Promise.reject(errorMessage);
                } else {
                    const request = $http({
                        url: `${apiHost}/v1/producer/producerlead`,
                        method: 'POST',
                        data: data
                    });
                    return apiRequest(request);
                }
            },
            findLeads: function (params) {
                const request = $http({
                    url: `${apiHost}/v1/producer/producerlead`,
                    method: 'GET',
                    params: params
                });
                return apiRequest(request);
            },
            downloadProducersReport: function () {
                const request = $http({
                    url: `${apiHost}/v1/producer/producer/producers_report`,
                    method: 'GET',
                });
                return apiRequest(request);
            },
            findProducers: function (params) {
                const request = $http({
                    url: `${apiHost}/v1/producer/producer`,
                    method: 'GET',
                    params: params
                });
                return apiRequest(request);
            },
            findProducerById: function (producerId) {
                const request = $http.get(`${apiHost}/v1/producer/producer/${producerId}`);
                return apiRequest(request);
            },
            findLeadUrl: function (leadId, pageId, orgIdOverride) {
                const request = $http({
                    url: `${apiHost}/v1/producer/producerlead/${leadId}/url`,
                    method: 'GET',
                    params: { pageId: pageId, orgIdOverride }
                }).then((res) => {
                    return res;
                });
                return apiRequest(request);
            },
            updateProducer: function (producerId, updates) {
                const request = $http({
                    url: `${apiHost}/v1/producer/producer/${producerId}`,
                    method: 'POST',
                    data: updates
                });
                return apiRequest(request);
            },
            changeProducerPassword: function (producerId) {
                const request = $http({
                    url: `${apiHost}/v1/producer/producer/${producerId}/change_password`,
                    method: 'POST'
                });
                return apiRequest(request);
            },
            findOrganizations: function (params) {
                return apiRequest($http.get(`${apiHost}/v1/producer/organization`, params));
            },
            findOrganization: function (organizationId) {
                return apiRequest($http.get(`${apiHost}/v1/producer/organization/${organizationId}`));
            },
            checkOrganizationRestriction: function (organizationId, state, product) {
                const request = $http({
                    url: `${apiHost}/v1/producer/organization/${organizationId}/restriction`,
                    method: 'GET',
                    params: {
                        state,
                        product
                    }
                });
                return apiRequest(request);
            },
            updateOrganization: function (organizationId, updates) {
                const request = $http({
                    url: `${apiHost}/v1/producer/organization/${organizationId}`,
                    method: 'POST',
                    data: updates
                });
                return apiRequest(request);
            },
            findAvailableOrganizationsForUser: function () {
                const request = $http({
                    url: `${apiHost}/v1/producer/current_orgs`,
                    method: 'GET'
                });
                return apiRequest(request);
            },
            updatePolicyEffectiveDate: function (policyId, effectiveDate) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policy/${policyId}/effective_date`,
                    method: 'POST',
                    data: { effective_date: effectiveDate }
                });
                return apiRequest(request);
            },
            updatePolicyMortgages: function (policyId, lenders) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policy/${policyId}/mortgages`,
                    method: 'POST',
                    data: { lenders }
                });
                return apiRequest(request);
            },
            updatePolicyOrganization: function (policyId, orgId) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policy/${policyId}/organization`,
                    method: 'POST',
                    data: { orgId }
                });
                return apiRequest(request);
            },
            updateProducerPolicy: function (policyId, context, updates) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policy/${policyId}/update/${context}`,
                    method: 'POST',
                    data: {policyInfo: updates}
                });
                return apiRequest(request);
            },
            updatePersonalInformation: function(policyId, updates) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policy/${policyId}/personal_information`,
                    method: 'POST',
                    data: updates
                });
                return apiRequest(request);
            },

            // download policy report
            downloadLeadReport: function (filters) {
                const request = $http({
                    url: `${apiHost}/v1/producer/leadReport`,
                    method: 'GET',
                    params: filters
                });
                return apiRequest(request);
            },
            // download policy report
            downloadPolicyReport: function (filters) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policyReport`,
                    method: 'GET',
                    params: filters
                });
                return apiRequest(request);
            },

            // stats
            findDashboardStats: function (filters) {
                const request = $http({
                    url: `${apiHost}/v1/producer/stats`,
                    method: 'GET',
                    params: filters
                });
                return apiRequest(request);
            },

            // notifications
            findNotifications: function (params) {
                const request = $http({
                    url: `${apiHost}/v1/producer/notification`,
                    method: 'GET',
                    params: params
                });
                return apiRequest(request);
            },
            markNotificationsRead: function (params) {
                const request = $http({
                    url: `${apiHost}/v1/producer/notification/mark_read`,
                    method: 'POST',
                    data: params
                });
                return apiRequest(request);
            },
            markNotificationsUnread: function (params) {
                const request = $http({
                    url: `${apiHost}/v1/producer/notification/mark_unread`,
                    method: 'POST',
                    data: params
                });
                return apiRequest(request);
            },
            updateNotificationFavorite: function (notificationId, favorite) {
                const request = $http({
                    url: `${apiHost}/v1/producer/notification/${notificationId}/favorite`,
                    method: 'POST',
                    data: {favorite}
                });
                return apiRequest(request);
            },
            resendCustomerAgreementEmail: function (policyId) {
                const request = $http({
                    url: `${apiHost}/v1/producer/policy/${policyId}/resend_agreement`,
                    method: 'POST',
                    cache: false
                });
                return apiRequest(request);
            },
            getReports: function (orgId) {
                const request = $http({
                    url: `${apiHost}/v1/producer/organization/${orgId}/organization_reports`,
                    method: 'POST'
                });
                return apiRequest(request);
            },
            downloadYTDReport: function (url) {
                const request = $http({ url, method: 'POST' });
                return apiRequest(request);
            },

            getApplicationId: function(leadId) {
                const request = $http({
                    url: `${apiHost}/v1/producer/producerlead/${leadId}/application_id`,
                    method: 'GET',
                });
                return apiRequest(request);
            },

            setIntent: function(quoteId) {
                const request = $http({
                    url: `${apiHost}/v1/producer/producerlead/${quoteId}/intent`,
                    method: 'POST',
                });
                return apiRequest(request);
            },
        };

        return APIService;

        function apiRequest (request) {
            const deferred = $q.defer();

            request.then(function (res) {
                deferred.resolve(res.data);
            }, function (err) {
                $log.info('this is error', err);

                if (err.data && err.data.error) {
                    // toaster.pop('error', "Error", err.data.error.customMsg);
                } else {
                    // toaster.pop('error', "Error", "");
                }
                deferred.reject(err.data);
            });

            return deferred.promise;
        }
    });

    MyHippoProducerServices.factory('UploadService', function () {
        return {};
    });

    MyHippoProducerServices.factory('TimeRangeFilterService', function () {
        const validTimeRanges = ['month_to_date', 'last_30_days', 'quarter_to_date', 'year_to_date', 'last_12_months', 'last_year', ''];
        let currentTimeRange = 'last_30_days'; // default
        return {
            setTimeRange: function (timeFrame) {
                if (validTimeRanges.includes(timeFrame)) {
                    currentTimeRange = timeFrame;
                }
            },
            getTimeRange: function () {
                return currentTimeRange;
            }
        };
    });
})(window.angular, window._);
